import React from "react";
import "../Styles/Process.css";
import upImg from "../Assets/service-sidebar-1-1.jpg";
import mainImg from "../Assets/services-6.jpg";
import bottomImg from "../Assets/about-3-1.png";

function Process() {
  return (
    <>
      <div className="process_container">
        <div className="scontainer">
          <div className="row">
            <div
              data-aos="fade-right"
              // data-aos-duration="1500"
              data-aos-mirror="true"
              data-aos-once="false"
              data-aos-easing="ease-in-out"
              className="col-lg-5"
            >
              <div className="process_left">
                <img src={mainImg} alt="mainImg" className="main_img" />
                <img src={upImg} alt="UpImg" className="up_image" />
                <img src={bottomImg} alt="BottomImg" className="bottom_img" />
              </div>
            </div>
            <div
              data-aos="fade-left"
              // data-aos-duration="1500"
              data-aos-mirror="true"
              data-aos-once="false"
              data-aos-easing="ease-in-out"
              className="col-lg-7 pd_70"
            >
              <div className="process_right">
                <p className="service_sub_title">Process</p>
                <h1 className="service_title ">Our Company Work Process.</h1>
                <div className="process_detail_container">
                  <div className="process_number">
                    <h1>01</h1>
                  </div>
                  <div className="process_text">
                    <h4>Discussions About Loan</h4>
                    <p>
                      Discuss loans with fellow borrowers and lenders in our
                      forum. Join the conversation about borrowing and lending
                      in our "Discussions About Loan" section.
                    </p>
                  </div>
                </div>
                <div className="process_detail_container">
                  <div className="process_number">
                    <h1>02</h1>
                  </div>
                  <div className="process_text">
                    <h4>Start Work With Team</h4>
                    <p>
                      We suddenly join our Start Work With Team section to
                      connect with like-minded individuals and form a team.
                    </p>
                  </div>
                </div>
                <div className="process_detail_container">
                  <div className="process_number">
                    <h1>03</h1>
                  </div>
                  <div className="process_text">
                    <h4>Handover & Save Time</h4>
                    <p>
                      Learn how to streamline your workflow and save time with
                      our Handover & Save Time section. Discover tips and tricks
                      for effective handovers and seamless project transitions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Process;
