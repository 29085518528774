const contactData = [
  {
    id: 1,
    title: "Email Address",
    subTitle: "Sent mail asap anytime",
    desc1: "Pradhanmantrimudrayojna.gov@g...",
    icon: "fa-solid fa-envelope",
  },
  {
    id: 2,
    title: "Phone Number",
    subTitle: "Call us asap anytime",
    desc1: "+91 9091089804",
    icon: "fa-solid fa-phone",
  },
  {
    id: 3,
    title: "Office Address",
    subTitle: "Come to our office anytime",
    desc1: "193/1, Muni Maya Ram Jain Marg, Netaji Subhash Place, Shakurpur, New Delhi, 1100034",
    icon: "fa-solid fa-location-dot",
  },
];

export default contactData;
