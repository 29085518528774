import React from "react";
import skillImg from "../Assets/Services/about_small.png";
import "../Styles/Skill.css";

function Skill() {
  return (
    <>
      <div className="skill_container">
        <div className="scontainer">
          <div className="skill_box">
            <div className="row align">
              <div   data-aos="fade-right"
                  // data-aos-duration="1500"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  data-aos-easing="ease-in-out" className="col-xl-5">
                <img src={skillImg} className="skill_img" alt="Skill Image" />
              </div>
              <div   data-aos="fade-left"
                  // data-aos-duration="1500"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  data-aos-easing="ease-in-out" className="col-xl-7">
                <div className="skill_text skill">
                  <p className="exp_super_title">Our Skill Test</p>
                  <h1 className="exp_title ">
                    Our Professional and Creative Team
                  </h1>
                  <p className="exp_sub_title">
                    Capitalise on low hanging fruit to identify a ballpark value
                    added activity to beta test.
                  </p>
                </div>
                <div className="bars">
                  <div className="bar">
                    <p className="bar_title">Investment Plan</p>
                    <div className="bar_bg">
                      <p className="bar_per bar_per1">77%</p>
                    </div>
                  </div>
                </div>
                <div className="bars">
                  <div className="bar">
                    <p className="bar_title">Consulting Experience</p>
                    <div className="bar_bg">
                      <p className="bar_per bar_per2">68%</p>
                    </div>
                  </div>
                </div>
                <div className="bars">
                  <div className="bar">
                    <p className="bar_title">Industry</p>
                    <div className="bar_bg">
                      <p className="bar_per bar_per3">75%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Skill;
