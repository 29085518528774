import property_loan from "../Assets/Services/property_loan.jpg";
import wedding_loan from "../Assets/Services/wedding_loan.jpg";
import personal_loan from "../Assets/Services/personal_loan.jpg";
import business_loan from "../Assets/service-three.jpg";
const approachData = [
  {
    id: 1,
    img: property_loan,
    title: "Property Loan",
    desc: "Our property loans offer flexible financing solutions to help you purchase or refinance your home or investment property.",
  },
  {
    id: 2,
    img: wedding_loan,
    title: "Wedding Loan",
    desc: "Low interest rates and flexible repayment options, making it easy for you to finance your big day without breaking the bank.",
  },
  {
    id: 3,
    img: personal_loan,
    title: "Personal Loan",
    desc: "Our personal loans come with flexible repayment terms and competitive interest rates. Apply now and get approved in minutes.",
  },
  {
    id: 3,
    img: business_loan,
    title: "Business Loan",
    desc: "Grow your business with our flexible and affordable business loans. We provide the funds you need to expand your operations, invest in new equipment, or manage cash flow.",
  },
];

export default approachData;
