import React, { useState } from "react";
import "../Styles/FAQ.css";

function FAQ({ que, ans }) {
  const [showAns, setShowAns] = useState(false);

  const showHideAns = () => {
    setShowAns(!showAns);
  };
  return (
    <>
      <div className="accordion_container">
        <div className="ques_ans_box">
          <div className="ques_box" onClick={showHideAns}>
            <h4 className="ques">{que}</h4>
            <i
              className="fa-solid fa-angle-right"
              style={
                showAns
                  ? { webkitTransform: "rotate(88deg)" }
                  : { webkitTransform: "rotate(0deg)" }
              }
            ></i>
          </div>
          <div className="ans_box">
            <p className={showAns ? "ansopen" : "ans"}>{ans}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQ;
