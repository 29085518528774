import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function PrivacyPolicy() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <div
        style={{ justifyContent: "center", alignItems: "center", margin: 40 }}
      >
        <h1
          style={{
            fontSize: 40,
            color: "#F2BB32",
            fontWeight: "bold",
            marginTop: 40,
            marginBottom: 40,
          }}
        >
          Privacy Policy
        </h1>
        <h2 style={{ marginBottom: 20 }}>1. Introduction</h2>
        <p style={{ marginBottom: 40 }}>
          Thank you for visiting our website. This Privacy Policy explains how
          we collect, use, and protect your personal information when you use
          our services. By accessing or using our website, you agree to the
          terms outlined in this Privacy Policy.
        </p>
        <h2 style={{ marginBottom: 20 }}>2. Information We Collect</h2>
        <p style={{ marginBottom: 40 }}>
          We may collect personal information from you when you fill out forms,
          subscribe to our newsletter, or engage in any other activities or
          services we provide on our website. The types of personal information
          we may collect include:<br></br>
          <br></br>
          <p>1. Name</p>
          <p>2. Contact information (email address, phone number, etc.)</p>
          <p>3. Demographic information</p>
          <p>3. Financial information</p>
          <p>3. Other information relevant to loan services</p>
        </p>
        <h2 style={{ marginBottom: 20 }}>3. How We Use Your Information</h2>
        <p style={{ marginBottom: 40 }}>
          We may use your personal information for the following purposes:
          <br></br>
          <br></br>
          <p>1. Provide the loan services and information you request</p>
          <p>2. Personalize your experience on our website</p>
          <p>3. Improve our website and services</p>
          <p>
            3. Send you promotional emails or newsletters (you can unsubscribe
            anytime)
          </p>
          <p>3. Contact you for market research purposes</p>
        </p>
        <h2 style={{ marginBottom: 20 }}>4. Data Security</h2>
        <p style={{ marginBottom: 40 }}>
          We are committed to ensuring that your personal information is secure.
          We implement reasonable security measures to prevent unauthorized
          access, disclosure, alteration, or destruction of your personal
          information.
        </p>
        <h2 style={{ marginBottom: 20 }}>
          5. Cookies and Tracking Technologies
        </h2>
        <p style={{ marginBottom: 40 }}>
          Our website may use cookies and similar tracking technologies to
          enhance your browsing experience. You can choose to accept or decline
          cookies. Most web browsers automatically accept cookies, but you can
          usually modify your browser settings to decline cookies if you prefer.
        </p>
        <h2 style={{ marginBottom: 20 }}>6. Third-Party Websites</h2>
        <p style={{ marginBottom: 40 }}>
          Our website may contain links to third-party websites. Once you leave
          our website, we are not responsible for the protection and privacy of
          any information you provide on those websites. We recommend reviewing
          the privacy statements of those websites before providing any personal
          information.
        </p>
        <h2 style={{ marginBottom: 20 }}>7. Changes to the Privacy Policy</h2>
        <p style={{ marginBottom: 40 }}>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and the revised version will be effective
          immediately.
        </p>
      </div>
    </>
  );
}

export { PrivacyPolicy };
