import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../Styles/Navbar.css";
import Header from "./Header";


function Navbar() {
  const [showMenu, setShowMenu] = useState(true);

  const showHideMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <>
      <Header showHideMenu={showHideMenu} />
      <section  className={showMenu ? "navbar" : "open"} >
        <div   className="nav_links">
          <ul>
            <li>
              <NavLink to="/" onClick={showHideMenu}>
                Home
              </NavLink>
              <NavLink to="/about" onClick={showHideMenu}>
                About
              </NavLink>
              
              <NavLink to="/service" onClick={showHideMenu}>
                Services
              </NavLink>
              <NavLink to="/contact" onClick={showHideMenu}>
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Navbar;
