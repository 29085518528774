import React, { useState, useEffect, useRef } from "react";
import "../Styles/Contact.css";
import Hero from "../Components/Hero";
import contactData from "../Data/ContactData";

import { useLocation } from "react-router-dom";

import emailjs from "@emailjs/browser";

function Contact() {
  const name = useRef(null);
  const email = useRef(null);
  const phone = useRef(null);
  const subject = useRef(null);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const [data] = useState(contactData);
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };
  const [formValue, setFormValue] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const validation = (values) => {
    const errors = {};
    const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!values.name) {
      errors.name = "Please Enter Name *";
      const scrollHeight = name.current.offsetTop;
      window.scrollTo({
        top: scrollHeight,
        behavior: "smooth",
      });
    } 
    
    // else if (!values.email) {
    //   errors.email = "Please Enter Email *";
    //   const scrollHeight = email.current.offsetTop;
    //   window.scrollTo({
    //     top: scrollHeight,
    //     behavior: "smooth",
    //   });
    // } 
    
    // else if (!emailPattern.test(values.email)) {
    //   errors.email = "Enter a valid Email *";
    //   const scrollHeight = email.current.offsetTop;
    //   window.scrollTo({
    //     top: scrollHeight,
    //     behavior: "smooth",
    //   });
    // } 
    else if (!values.phone) {
      errors.phone = "Please Enter Phone *";
      const scrollHeight = phone.current.offsetTop;
      window.scrollTo({
        top: scrollHeight,
        behavior: "smooth",
      });
    } else if (values.phone.length > 10) {
      errors.phone = "Phone can't be more than 10 digit *";
      const scrollHeight = phone.current.offsetTop;
      window.scrollTo({
        top: scrollHeight,
        behavior: "smooth",
      });
    } else if (values.phone.length < 10) {
      errors.phone = "Phone can't be less than 10 digit *";
      const scrollHeight = phone.current.offsetTop;
      window.scrollTo({
        top: scrollHeight,
        behavior: "smooth",
      });
    } 
    // else if (!values.subject) {
    //   errors.subject = "Please Enter Subject *";
    //   const scrollHeight = subject.current.offsetTop;
    //   window.scrollTo({
    //     top: scrollHeight,
    //     behavior: "smooth",
    //   });
    // }
     else {
      alert(
        `Form Submitted Successfully! 
We will catch you soon`
      );
      values.name = "";
      values.email = "";
      values.phone = "";
      values.subject = "";
      values.message = "";
    }
    return errors;
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setFormErrors(validation(formValue));
    emailjs
      .sendForm(
        "service_lwvb2zo",
        "template_pf2zqjd",
        form.current,
        "noe_ruuOARN3ipKY8"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("form sent!");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="contact_container">
        <Hero link="Home" pageName="Contact Us" />
      </div>
      <div
        data-aos="fade-down"
        // data-aos-duration="1500"
        data-aos-mirror="true"
        data-aos-once="false"
        data-aos-easing="ease-in-out"
        className="contact_cards"
      >
        <div className="scontainer">
          <div className="row">
            {data.map((detail) => {
              const { id, title, subTitle, desc1, desc2, icon } = detail;
              return (
                <div className="col-xl-4 col-md-6 col-sm-12" key={id}>
                  <div className="contact_card">
                    <div className="contact_card_top">
                      <div className="contact_circle">
                        <i className={icon}></i>
                      </div>
                      <div className="title_box">
                        <h4>{title}</h4>
                        <p>{subTitle}</p>
                      </div>
                    </div>
                    <div className="contact_card_bottom">
                      <div className="contact_bottom_left">
                        <p>{desc1}</p>
                        <p>{desc2}</p>
                      </div>
                      <div className="contact_bottom_right">
                        <div className="circle_white">
                          <i className="fa-solid fa-right-long"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        data-aos="fade-up"
        // data-aos-duration="1500"
        data-aos-mirror="true"
        data-aos-once="false"
        data-aos-easing="ease-in-out"
        className="scontainer"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14000.34352886204!2d77.14113549999999!3d28.687077450000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03ca2507c9ab%3A0xd95eb11d9cfb9075!2sShakurpur%2C%20Delhi%2C%20110034!5e0!3m2!1sen!2sin!4v1703322961574!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0, marginBottom: "25px" }}
          loading="lazy"
          allowfullscreen=""
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="contact_form_container">
        <div className="scontainer">
          <div
            data-aos="fade-down"
            // data-aos-duration="1500"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-easing="ease-in-out"
          >
            <p className="service_sub_title text-center">Contact</p>
            <h1 className="service_title text-center">Get In Touch</h1>
          </div>
          <form
            data-aos="fade-left"
            // data-aos-duration="1500"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-easing="ease-in-out"
            ref={form}
            className="contact_form"
            action="https://submit-form.com/b3XpMIB1"
            method="POST"
            onSubmit={sendEmail}
          >
            <div className="row">
              <div className="col-md-6">
                <div
                  data-aos="fade-down"
                  // data-aos-duration="1500"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  data-aos-easing="ease-in-out"
                  ref={name}
                  className="error_label"
                >
                  <label htmlFor="name">
                    Full Name <span className="required">*</span>
                  </label>
                </div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter Your Name"
                  value={formValue.name}
                  onChange={handleInputChange}
                />
                <p className="val_msg name">{formErrors.name}</p>
              </div>
              <div className="col-md-6">
                <div ref={phone} className="error_label">
                  <label htmlFor="phone">
                    Phone Number <span className="required">*</span>
                  </label>
                </div>
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  placeholder="Enter Your Mobile"
                  value={formValue.phone}
                  onChange={handleInputChange}
                />
                <p className="val_msg name">{formErrors.phone}</p>
              </div>
            </div>
            <div className="row">
            

              <div className="col-md-6">
                <div ref={email} className="error_label">
                  <label htmlFor="email">
                    Email Address 
                  </label>
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Your Email"
                  value={formValue.email}
                  onChange={handleInputChange}
                />
                <p className="val_msg name">{formErrors.email}</p>
              </div>
              <div className="col-md-6">
                <div ref={subject} className="error_label">
                  <label htmlFor="subject">
                    Subject
                  </label>
                </div>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="Enter Your Subject"
                  value={formValue.subject}
                  onChange={handleInputChange}
                />
                <p className="val_msg name">{formErrors.subject}</p>
              </div>
            </div>
            {/* <div className="row">
              <label htmlFor="subject">Enter Message</label>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="7"
                placeholder="Enter Message..."
                value={formValue.message}
                onChange={handleInputChange}
              ></textarea>
            </div> */}
            <input type="submit" className="btn contact_btn" />
          </form>
        </div>
      </div>
    </>
  );
}

export default Contact;
