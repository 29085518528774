import React, { useState, useEffect } from "react";
import "../Styles/Home.css";
import { Link } from "react-router-dom";
import {
  Service,
  Experience,
  CallUs,
  FAQS,
  Process,
} from "../Components/IndexComponents";
import { Modal } from "../Components/Modal";
import { useLocation } from "react-router-dom";

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
      document.body.style.overflowX = "hidden";
    }
  });
  return (
    <>
      <div className="home_container">
        <div
          data-aos="fade-right"
          // data-aos-duration="1000"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-easing="ease-in-out"
          className="hero_text"
        >
          <h1 className="hero_title">
            Connecting <br></br>Your Loan Needs
          </h1>
        </div>
        <div className="hero_btns">
          {/* <a onClick={() => setModalOpen(!modalOpen)}> 
            <button className="btn hero_btn">Apply For Loan &#8594;</button>
            </a> */}
          <a data-aos="fade-right"
          // data-aos-duration="1000"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-easing="ease-in-out">
            <button
              className="btn black_bg hero_btn"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                window.scrollTo(0, 0);
              }}
            >
              Apply For Loan &#10132;
            </button>
          </a>
          <Link to="/about" data-aos="fade-left"
          // data-aos-duration="1500"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-easing="ease-in-out">
            <button className="btn black_bg hero_btn">
              Learn More &#10132;
            </button>
          </Link>
        </div>
      </div>
      <Experience />
      <Service />
      <CallUs />
      {/* <NewsLetter /> */}
      <FAQS />
      <Process />

      {isModalOpen && (
        <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      )}
    </>
  );
}

export default Home;
