import React, { useState } from "react";
import pricingData from "../Data/PricingData";
import { Link } from "react-router-dom";
import "../Styles/Pricing.css";

function Pricing() {
  const [data] = useState(pricingData);
  return (
    <>
      <div className="pricing_container">
        <div className="scontainer">
          <p className="service_sub_title text-center">Easy Planning</p>
          <h1 className="service_title text-center">Price & Plans</h1>
          <br></br>
          <div className="row">
            {data.map((detail) => {
              const {
                id,
                title,
                price,
                subTitle,
                feature1,
                feature2,
                feature3,
                feature4,
                feature5,
                feature6,
                feature7,
              } = detail;
              return (
                <div className="col-md-4" key={id}>
                  <div className="pricing_card">
                    <h1 className="pricing_price">
                      <span>$</span>
                      {price}
                      <span>00</span>
                    </h1>
                    <h4 className="pricing_title">{title}</h4>
                    <p className="pricing_sub_title">{subTitle}</p>
                    <div className="pricing_features">
                      <p>
                        <i class="fa-solid fa-angles-right"></i>
                        &nbsp;{feature1}
                      </p>
                      <p>
                        <i class="fa-solid fa-angles-right"></i>
                        &nbsp;{feature2}
                      </p>
                      <p>
                        <i class="fa-solid fa-angles-right"></i>
                        &nbsp;{feature3}
                      </p>
                      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{feature4}</p>
                      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{feature5}</p>
                      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{feature6}</p>
                      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{feature7}</p>
                    </div>
                    <Link className="btn pricing_btn" to="/contact">
                      Get Your Plan Done
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
