import React from "react";
import "../Styles/Footer.css";
// import footer1 from "../Assets/footer1.jpg";
import footer2 from "../Assets/news_small2.jpg";
import footer3 from "../Assets/news_small.jpg";
import logo from "../Assets/logo.png";
import { Link } from "react-router-dom";

const currentYear = new Date().getFullYear();
function Footer() {
  return (
    <>
      <div className="footer_container">
        <div className="scontainer">
          <div className="row">
            <div
              data-aos="fade-right"
              // data-aos-duration="1500"
              data-aos-mirror="true"
              data-aos-once="false"
              data-aos-easing="ease-in-out"
              className="col-xl-3 col-md-6 col-sm-12 footer_about"
            >
              <div className="footer_about_text">
                <h6 className="footer_title">About Us</h6>
                <p>
                  Welcome to our company, your trusted partner in financing. We
                  are a leading online lender dedicated to providing reliable,
                  transparent, and convenient lending solutions to individuals
                  and businesses of all sizes.
                </p>
              </div>
              <div className="footer_social_icons">
                <div className="footer_icons_box">
                  <a href="https://www.facebook.com/">
                    <i class="fa-brands fa-facebook-f"></i>
                  </a>
                </div>

                <div className="footer_icons_box">
                  <a href="https://www.instagram.com/">
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                </div>

                <div className="footer_icons_box">
                  <a href="https://www.twitter.com/">
                    <i class="fa-brands fa-twitter"></i>
                  </a>
                </div>

                <div className="footer_icons_box">
                  <a href="https://www.linkedin.com/">
                    <i class="fa-brands fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-down"
              data-aos-duration="1500"
              data-aos-mirror="true"
              data-aos-once="false"
              data-aos-easing="ease-in-out"
              className="col-xl-3 col-md-6 col-sm-12"
            >
              <h1 className="footer_title">News Feeds</h1>
              <div className="footer_news_container">
                {/* <div className="footer_news_content">
                  <div className="footer_img">
                    <img src={footer1} alt="footer Img1" />
                  </div>
                  <div className="footer_news_text">
                    <p className="footer_news_time">
                      <i class="fa-solid fa-calendar-days"></i> 24th November
                      2023
                    </p>
                    <h4 className="footer_news_desc">
                      The Most Successful Engineering Contractor
                    </h4>
                  </div>
                </div> */}
                <div className="footer_news_content">
                  <div className="footer_img">
                    <img src={footer2} alt="footer Img1" />
                  </div>
                  <div className="footer_news_text">
                    <p className="footer_news_time">
                      <i class="fa-solid fa-calendar-days"></i> 24th November
                      2022
                    </p>
                    <h4 className="footer_news_desc">
                      How to get education loan for overseas
                    </h4>
                  </div>
                </div>
                <div className="footer_news_content">
                  <div className="footer_img">
                    <img src={footer3} alt="footer Img1" />
                  </div>
                  <div className="footer_news_text">
                    <p className="footer_news_time">
                      <i class="fa-solid fa-calendar-days"></i> 24th November
                      2023
                    </p>
                    <h4 className="footer_news_desc">
                      For Car auto you will get 90% loan amount
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-up"
              // data-aos-duration="1500"
              data-aos-mirror="true"
              data-aos-once="false"
              data-aos-easing="ease-in-out"
              className="col-xl-3 col-md-6 col-sm-12"
            >
              <h1 className="footer_title">Newsletter</h1>
              <div className="footer_newsletter_container">
                <div className="footer_newsletter_text">
                  <p>
                    Subscribe our newsletter to get our latest updates & news.
                  </p>
                  <form className="footer_form">
                    <input type="text" placeholder="Enter email address" />
                    <button className="footer_news_letter">
                      <a href="mailto:shavez9119@gmail.com">
                        <i class="fa-solid fa-paper-plane" title="Send"></i>
                      </a>
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-left"
              // data-aos-duration="1500"
              data-aos-mirror="true"
              data-aos-once="false"
              data-aos-easing="ease-in-out"
              className="col-xl-3 col-md-6 col-sm-12"
            >
              <h1 className="footer_title">Get In Touch</h1>
              <div className="footer_news_content">
                <div className="footer_call_box">
                  <i class="fa-solid fa-phone"></i>
                </div>
                <div className="footer_news_text">
                  <p className="footer_news_time">
                    <i class="fa-solid fa-phone"></i>&nbsp; Phone Number
                  </p>
                  <h4 className="footer_news_desc">+91 9091089804</h4>
                </div>
              </div>
              <div className="footer_news_content">
                <div className="footer_call_box">
                  <i class="fa-solid fa-envelope"></i>
                </div>
                <div className="footer_news_text">
                  <p className="footer_news_time">
                    <i class="fa-solid fa-envelope"></i>&nbsp; Email Us
                  </p>
                  <h4 className="footer_news_desc">
                  Pradhanmantrimudra<br></br>yojna.gov@gmail.com
                  
                  </h4>
                </div>
              </div>
              <div className="footer_news_content">
                <div className="footer_call_box">
                  <i class="fa-solid fa-location-arrow"></i>
                </div>
                <div className="footer_news_text">
                  <p className="footer_news_time">
                    <i class="fa-solid fa-location-arrow"></i>&nbsp; Location
                  </p>
                  <h4 className="footer_news_desc">193/1, Muni <br></br>Maya Ram Jain Marg,<br></br> Netaji Subhash Place,<br></br> Shakurpur, <br></br>New Delhi, 1100034</h4>
                </div>
              </div>
              <div className="footer_news_content">
                <div className="footer_call_box">
                  <i class="fa-brands fa-whatsapp"></i>
                </div>
                <div className="footer_news_text">
                  <p className="footer_news_time">
                    <i class="fa-brands fa-whatsapp"></i>&nbsp; Whatsapp
                  </p>
                  <h4 className="footer_news_desc">+91 9091089804</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 pd_0">
          <div className="footer_bottom">
            <div style={{ display: "flex" }}>
              <p>© Copyright By InstaLoanFinance - {currentYear}</p>
              <Link to="terms_of_use">
                <p
                  style={{
                    marginLeft: 23,
                    fontWeight: "bold",
                    fontSize: 19,
                    textDecoration: "underline",
                  }}
                >
                  Terms of Use
                </p>
              </Link>
              <Link to="privacy_policy">
                <p
                  style={{
                    marginLeft: 23,
                    fontWeight: "bold",
                    fontSize: 19,
                    textDecoration: "underline",
                  }}
                >
                  Privacy Policy
                </p>
              </Link>
            </div>
            <img src={logo} alt="Footer Logo" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
