import React, { useState, useEffect } from "react";
import Hero from "../Components/Hero";
import aboutImg from "../Assets/about_1.png";
import "../Styles/About.css";
import approachData from "../Data/ApproachData";
import { Link } from "react-router-dom";
import Skill from "../Components/Skill";
import { useLocation } from 'react-router-dom';

function About() {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const [data] = useState(approachData);
  return (
    <>
      <div className="about_container1">
        <Hero link="Home" pageName="About Us" />
      </div>


      
      <div className="table-container">
      <h1 className="exp_title" style={{fontWeight:"bold"}}>MUDRA Loan For Business</h1><br></br>
      <p className="exp_sub_title">Under the new definition, the distinction between manufacturing and services enterprises has
been eliminated. Under the new definition of MUDRA, the investment criteria for such enterprises
have been revised upwards, while an additional criterion of turnover has been introduced.
</p><br></br>
<h1 className="exp_title">Sector/Enterprise Type</h1>
<ol>
  <li style={{listStyle:"outside"}}>Manufacturing & Services Sector, Both</li>
</ol>


<table className="responsive-table">
        <thead>
          <tr>
            <th>Micro</th>
            <th>Small</th>
            <th>Medium</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Investment less than Rs. 1 crore</td>
            <td>Investment less than Rs. 10 crore</td>
            <td>Investment less than Rs. 50 crore</td>
          </tr>
          <tr>
            <td>Turnover less than Rs. 5 crore</td>
            <td>Turnover less than Rs. 50 crore</td>
            <td>Turnover less than Rs. 250 crore</td>
          </tr>
        </tbody>
      </table><br></br>

      <h5 style={{fontWeight:"bold"}}>Key Takeaways of New MSME Definition introduced in 'Atmanirbhar Bharat Abhiyan‘ Scheme
2020 by Indian Government.</h5>
<ol>
  <li style={{listStyle:"outside"}}>Collateral Free MSME business Loan</li>
  <li style={{listStyle:"outside"}}>MSME Loan worth of Rs. 3 lakh crore</li>
  <li style={{listStyle:"outside"}}>Moratorium period offered is 12 months</li>
  <li style={{listStyle:"outside"}}>Manufacturing and Service MSMEs shall be considered as same entities</li>
  <li style={{listStyle:"outside"}}>Repayment Tenure of 48 months</li>
  <li style={{listStyle:"outside"}}>100% Credit Guarantee</li>
  <li style={{listStyle:"outside"}}>To benefit approx. 45 lakh units</li>
</ol>

<h5 style={{fontWeight:"bold"}}>Pradhan Mantri MUDRA Yojana (PMMY)</h5>
<p className="exp_sub_title">Pradhan Mantri MUDRA Yojana (PMMY) is a scheme launched by the Hon’ble Prime Minister on
April 8, 2015 for providing loans up to 50 lakh to the non-corporate, non-farm small/micro
enterprises. These loans are classified as MUDRA loans under PMMY. These loans are given by
Commercial Banks, RRBs, Small Finance Banks, MFIs and NBFCs. The borrower can approach
any of the lending institutions mentioned above or can apply online through this portal. Under
the aegis of PMMY, MUDRA has created three products namely 'Shishu', 'Kishore' and 'Tarun' to
signify the stage of growth / development and funding needs of the beneficiary micro unit /
entrepreneur and also provide a reference point for the next phase of graduation / growth.</p><br></br>

<ol>
  <li style={{listStyle:"outside",fontSize:20,fontWeight:"bold"}}>Online Application:</li>
</ol>
<ol>
  <li style={{listStyle:"outside"}}>Digital lenders follow a fast track procedure and try to eliminate every possible delay
factor in the msme loan application and approval process. Online loan generally get
approved within a few working days, provided eligibility criteria are fulfilled.</li>
</ol><br></br>
<ol>
  <li style={{listStyle:"outside",fontSize:20, fontWeight:"bold"}}>Amount and Tenure of MSME Loan:</li>
</ol>
<ol>
  <li style={{listStyle:"outside"}}>Business owners can avail a msme business loan up to 50 lakhs. Also, the majority of
digital lending platforms adopt a flexible approach when it comes to repayment of MSME
loan. They generally follow tenure of 12 to36 months, to allow the businesses to repay it as
per their preferred schedule.</li>
</ol><br></br>
<ol>
  <li style={{listStyle:"outside",fontSize:20, fontWeight:"bold"}}>Hidden Charges:</li>
</ol>
<ol>
  <li style={{listStyle:"outside"}}>Availing MSME loan from digital lenders don’t come with any hidden or extra charges.</li>
</ol><br></br>
<ol>
  <li style={{listStyle:"outside",fontSize:20, fontWeight:"bold"}}>Flexible Repayment Option:</li>
</ol>
<ol>
  <li style={{listStyle:"outside"}}>Digital lenders offer ample of time and repayment options to its MSME/SME applicants.
The MSME loan tenure range from 1-36 months. Applicants also have an option to repay in
EMIs or bi-weekly or monthly installments.</li>
</ol><br></br>

<h5 style={{fontWeight:"bold"}}>SME / MSME Loan Fees & Interest Rates</h5><br></br>

  <table className="responsive-table">
    <tr>
      <th>Customized Interest Rates</th>
      <th>Starting @1.5% PM</th>
    </tr>
    <tr>
      <th>Processing Fees</th>
      <th>3%</th>
    </tr>
    <tr>
      <th>Loan Tenure</th>
      <th>up to 3 years</th>
    </tr>
    <tr>
      <th>Pre-closure Charges</th>
      <th>4%</th>
    </tr>
    <tr>
      <th>Eligibility Criteria</th>
      <th>5L Turnover in last 6 Months</th>
    </tr>
    <tr>
      <th>Loan Amount </th>
      <th>₹ 50,000 – ₹ 1 Crores</th>
    </tr>
    <tr>
      <th>Installments</th>
      <th>Monthly</th>
    </tr>
  </table><br></br><br></br>

  <h5 style={{fontWeight:"bold"}}>How to Apply Mudra Loan Online</h5><br></br>
  <h5>Here are the three easy steps to get an MSME/SME loan:</h5>
  <p>1.&nbsp;&nbsp;<span style={{fontWeight:"bold"}}>Application</span>: Applicants who wish to secure a Mudra loan can fill in an online application
form giving details about their fund requirement</p>
  <p>2.&nbsp;&nbsp;<span style={{fontWeight:"bold"}}>Sanction</span>: Applicants can expect a loan application approval the moment all their
documents and other submissions are verified.</p><br></br>

<h5 style={{fontWeight:"bold"}}>STAND-UP INDIA LOAN SCHEME</h5>
<p className="exp_sub_title">Stand-Up India Scheme Facilitates bank loans between 1 lakh and 1 Crore to at least one
Scheduled Caste (SC) or Scheduled Tribe (ST) borrower and at least one woman borrower per
bank branch for setting up a greenfield enterprise. This enterprise may be in manufacturing,
services, agri-allied activities or the trading sector. In case of non-individual enterprises at least
51% of the shareholding and controlling stake should be held by either an SC/ST or woman
entrepreneur</p><br></br>

      
    </div>
      <div className="about_containerdf">
        <div className="scontainer">
          <div className="row">
            <div   data-aos="fade-right"
                  // data-aos-duration="1500"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  data-aos-easing="ease-in-out" className="col-xl-5 col-md-6 col-sm-12">
              <img src={aboutImg} alt="About Image" />
            </div>
            <div  data-aos="fade-left"
                  // data-aos-duration="1500"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  data-aos-easing="ease-in-out" className="col-xl-7 col-md-6 col-sm-12">
              <div className="about_content">
                <p className="exp_super_title">
                  About Company
                </p>
                <h1 className="exp_title">Small business loans for daily expenses</h1>
                <p className="exp_sub_title">
                Welcome to [Company Name] your trusted partner in financing. We are a leading online lender dedicated to providing reliable, transparent, and convenient lending solutions to individuals and businesses of all sizes.
                </p>
              
                <div className="about_info">
                  <div className="about_circle">
                    <i class="fa-solid fa-road"></i>
                  </div>
                  <div className="about_info_text">
                    <h3>Our Mission</h3>
                    <p>
                    At [Company Name] our mission is to help people achieve their financial goals by providing fast, flexible, and affordable loans. We understand that life can be unpredictable, and unexpected expenses can cause stress and anxiety. That's why we strive to make the borrowing process as easy and straightforward as possible.
                    </p>
                  </div>
                </div>
                <div className="about_info">
                  <div className="about_circle">
                    <i class="fa-solid fa-walkie-talkie"></i>
                  </div>
                  <div className="about_info_text">
                    <h3>Our Values</h3>
                    <p>
                    Integrity, transparency, and customer satisfaction are at the core of everything we do. We believe in treating our customers with respect and honesty, and we are committed to providing exceptional service at every stage of the lending process.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="approach_container">
            <div   data-aos="fade-down"
                  // data-aos-duration="1500"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  data-aos-easing="ease-in-out">
            <p className="service_sub_title text-center">Loan We Provide</p>
            <h1 className="service_title text-center">We Provide In Various Category</h1>
            </div>
            <div className="row">
              {data.map((detail) => {
                const { id, img, title, desc } = detail;
                return (
                  <div   data-aos="fade-up"
                  // data-aos-duration="1500"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  data-aos-easing="ease-in-out" className="col-xl-4 col-md-6 col-sm-12">
                    <div className="approach_card">
                      <img src={img} alt="Approach Image" />
                      <div className="approach_card_text">
                        <h4>{title}</h4>
                        <p>{desc}</p>
                        <Link to="/contact">
                          Know more &nbsp;
                          <i class="fa-solid fa-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Skill />
      </div>
    </>
  );
}

export default About;