import serviceImg1 from "../Assets/serviceImg1.png";
import serviceImg2 from "../Assets/serviceImg2.png";
import serviceImg3 from "../Assets/serviceImg3.png";
import serviceImg4 from "../Assets/serviceImg4.png";
import serviceBigImg1 from "../Assets/serviceBigImg1.jpg";
import serviceBigImg2 from "../Assets/serviceBigImg2.jpg";
import serviceBigImg3 from "../Assets/serviceBigImg3.jpg";

const serviceCardData = [
  {
    id: 1,
    img: serviceImg1,
    bigImg: serviceBigImg1,
    title: "Property Loan",
    buttonText: "Learn More",
  },
  {
    id: 2,
    img: serviceImg1,
    bigImg: serviceBigImg2,
    title: "Wedding Loan",
    buttonText: "Learn More",
  },
  {
    id: 3,
    img: serviceImg1,
    bigImg: serviceBigImg3,
    title: "Personal Loan",
    buttonText: "Learn More",
  },
  {
    id: 4,
    img: serviceImg1,
    bigImg: serviceBigImg1,
    title: "Business Loan",
    buttonText: "Learn More",
  },
];

export default serviceCardData;
