import React from "react";
import analytics from "../Assets/analytics.png";
import "../Styles/Analytics.css";
import profileImg from "../Assets/profile.jpg";

function Experience() {
  return (
    <div className="experience_container margin_top_1001">
      <div className="scontainer">
        <div className="row exp_content">
          <div
            data-aos="fade-right"
            // data-aos-duration="1000"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-easing="ease-in-out"
            className="col-md-6 exp_right_container"
          >
            <p className="exp_super_title">Business Analytics</p>
            <h1 className="exp_title">Providing solutions of every kind</h1>
            <p className="exp_sub_title">
              In recent years, the loan requirement has been increasing steadily
              across various sectors of the economy. This trend is driven by a
              variety of factors, including economic growth, increasing
              population, and changing consumer preferences.
            </p>
            <br />
            <p className="exp_desc">
              One of the key drivers of the loan requirement increase is the
              growing demand for housing. As more people enter the housing
              market, the need for mortgages and other forms of financing has
              risen sharply. In addition, as property prices continue to
              increase, borrowers need to take out larger loans to afford their
              desired homes.
            </p>
          </div>
          <div
            data-aos="fade-left"
            // data-aos-duration="1000"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-easing="ease-in-out"
            className="col-md-6 exp_left_container"
          >
            <img src={analytics} alt="30 Years" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;
