import React from "react";
import "../Styles/CallUs.css";

function CallUs() {
  return (
    <>
      <div data-aos="fade-left"
          // data-aos-duration="1500"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-easing="ease-in-out" className="callus_container margin_top_1002">
        <h2 className="callus_title">
          Get an easy quotation for your industry
        </h2>
        <div className="call_container">
          <div className="call_circle">
            <i class="fa-solid fa-phone-flip"></i>
          </div>
          <div className="call_us">
            <a href="tel:9554178758">
              <h2>(+91) 9554178758</h2>
              <p>Call Us Now</p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default CallUs;
