const faqData = [
  {
    id: 1,
    que: "What is a loan and how does it work?",
    ans: "A loan is an amount of money borrowed from a lender, which must be repaid over time with interest. Loans can be used for a variety of purposes, such as buying a home, starting a business, or paying for education.",
  },
  {
    id: 2,
    que: "What are the different types of loans available?",
    ans: "There are many different types of loans available, including personal loans, auto loans, student loans, and mortgages. Each type of loan has its own pros and cons, such as the interest rate, repayment period, and collateral requirements. Personal loans are usually unsecured and have higher interest rates, while mortgages are secured by the home and have lower interest rates.",
  },
  {
    id: 3,
    que: "How do lenders determine whether to approve a loan app?",
    ans: "Lenders consider several factors when deciding whether to approve a loan application, including the borrower's credit score, income, employment history, and debt-to-income ratio. Lenders also consider the purpose of the loan and the collateral offered by the borrower.",
  },
  {
    id: 4,
    que: "What factors affect the interest rate on a loan?",
    ans: "Several factors can affect the interest rate on a loan, such as the borrower's credit score, income, and the current economic environment. Borrowers can get the best rate possible by improving their credit score, shopping around for the best rate, and providing collateral if possible.",
  },
  {
    id: 5,
    que: "What are the consequences of defaulting on a loan?",
    ans: "The consequences of defaulting on a loan can include damage to the borrower's credit score, collection actions such as wage garnishment and lawsuits, and loss of collateral. Borrowers can avoid defaulting by making payments on time, communicating with the lender if they experience financial hardship, and seeking assistance from a credit counselor if needed.",
  },
];

export default faqData;
