import smallImg1 from "../Assets/Blog/SmallImg1.webp";
import smallImg2 from "../Assets/Blog/SmallImg2.webp";
import smallImg3 from "../Assets/Blog/SmallImg3.webp";
import smallImg4 from "../Assets/Blog/SmallImg4.webp";
import smallImg5 from "../Assets/Blog/SmallImg5.jpeg";
import smallImg6 from "../Assets/Blog/SmallImg6.webp";
import smallImg7 from "../Assets/Blog/SmallImg7.webp";
import smallImg8 from "../Assets/Blog/SmallImg8.jpg";
import smallImg9 from "../Assets/Blog/SmallImg9.webp";
import smallImg10 from "../Assets/Blog/SmallImg10.webp";
import bigImg1 from "../Assets/Blog/bigImg1.webp";
import bigImg2 from "../Assets/Blog/bigImg2.webp";
import bigImg3 from "../Assets/Blog/bigImg3.jpeg";
import authorImg1 from "../Assets/Blog/authorImg1.png";
import authorImg2 from "../Assets/Blog/authorImg2.png";
import authorImg3 from "../Assets/Blog/authorImg3.png";

const blogDay = new Date().getDay();
const blogMonth = new Date().getMonth();
const blogTime = new Date().getTime();

const blogData = [
  {
    id: 1,
    title: "Facebook’s new ‘Feed’ tab chronologically displays posts",
    smallDesc:
      "Facebook is revamping its main news feed and launching a new “Feed” tab where users can see posts from their friends, groups, pages and more in chronological order, Meta CEO Mark Zucker",
    blogDay: blogDay,
    blogMonth: blogMonth,
    blogTime: blogTime,
    smallImg: smallImg1,
    bigImg: bigImg1,
    category: "Industrial",
    poster: "Aisha Malik",
    desc1:
      "“One of the most requested features for Facebook is to make sure people don’t miss friends’ posts,” Zuckerberg said in an announcement post. “So today we’re launching a Feeds tab where you can see posts from your friends, groups, Pages and more separately in chronological order. The app will still open to a personalized feed on the Home tab, where our discovery engine will recommend the content we think you’ll care most about. But the Feeds tab will give you a way to customize and control your experience further.”",
    desc2:
      "Facebook differentiates between the two feeds by noting that Home is a discovery engine for you to find new content and creators through algorithmic recommendations, whereas the Feeds tab provides an easy way to access content from the people and communities you’re already connected with on Facebook.",
    desc3:
      "It’s worth noting that there may be some overlap between the two feeds, as Facebook says some of your friends posts may still appear in the Home tab and that the Feed tab will feature some ads as well.",
    desc4:
      "The new Feed tab is a return to Facebook’s basic social media experience where your feed is mainly focused on activity and posts from your friends and groups. However, the revamped Home feed shows Meta’s continued desire to chase TikTok, which is its current biggest threat. Given the fact that the algorithmic Home feed is what users will see when they first open the Facebook app, it’s easy to see where Meta’s priorities lie.",
    bannerDesc:
      "Viral dreamcatcher keytar typewriter, aest hetic offal umami. Aesthetic polaroid pug pitchfork post-ironic.",
    heading1: "A cleansing hot shower or bath",
    heading2: "Lorem ipsum dolor sit amet, consectetur adipisicing",
    desc5:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus.",
    authorImg: authorImg1,
    authorDesc:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.",
    authorFB: "https://www.facebook.com",
    authorInsta: "https://www.instagram.com",
    authorTwitter: "https:www.twitter.com",
    authorLinkedIn: "https:www.linkedin.com",
  },
  {
    id: 2,
    title: "Sign up for free and tune in today for TC Sessions: Robotics",
    smallDesc:
      "TC Sessions: Robotics is finally here, and we can’t wait to get things started! If you don’t have your free pass yet, no worries — sign in here to register. Then join more than 3,000 global attendees online for a full day exploring the latest news, trends, products and insights across robotics and AI.",
    blogDay: blogDay,
    blogMonth: blogMonth,
    blogTime: blogTime,
    smallImg: smallImg2,
    bigImg: bigImg2,
    category: "Web Devlopment",
    poster: "Lauren Simonds",
    desc1:
      "We have a full agenda featuring some of the industry’s leading roboticists, founders, investors, academics and policymakers. Here’s just a sample of the topics and people ready to share their expertise. Note: The agenda will automatically reflect session times based on your time zone.",
    desc2:
      "Lab Work: If you’re looking for the bleeding edge of robotics and AI research, you’ve come to the right place. MIT CSAIL head Daniela Rus and CMU’s new head of robotics, Matthew Johnson-Roberson, will join us to discuss how schools are helping redefine robotics.",
    desc3:
      "The Fulfilling World of Warehouse Robotics: Logistics and fulfillment may well be the hottest category in robotics at the moment. Locus Robotics, Zebra Technologies (Fetch) and Berkshire Grey are helping define the space. Their automation is working to improve delivery times, manage inventory, assist their human counterparts and stay competitive.",
    desc4:
      "Pre-seed to Unicorn: Lessons from HAX Robotics Founders: SOSV’s HAX, the hands-on early-stage investor in hard tech, has been making big bets on robotics for ten years, and today the startup development program is the most active pre-seed investor in robotics in the world. Join HAX partner Garrett Winther and four HAX founders — ranging from its prolific unicorn, Opentrons to its more recent seed and series A stage investments — for robotics founder insights across all stages and geographies.",

    bannerDesc:
      "Join HAX partner Garrett Winther and four HAX founders — ranging from its prolific unicorn, Opentrons to its more recent seed and series A stage investments — for robotics founder insights across all stages and geographies.",
    heading1: "A cleansing hot shower or bath",
    heading2: "Lorem ipsum dolor sit amet, consectetur adipisicing",
    desc5:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus.",
    authorImg: authorImg2,
    authorDesc:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.",
    authorFB: "https://www.facebook.com",
    authorInsta: "https://www.instagram.com",
    authorTwitter: "https:www.twitter.com",
    authorLinkedIn: "https:www.linkedin.com",
  },
  {
    id: 3,
    title: "Airbnb co-founder Joe Gebbia steps back from leadership role",
    smallDesc:
      "Airbnb co-founder Joe Gebbia today announced he’s stepping down from his position at the company he helped to launch over a decade ago. In a letter to employees also published to Airbnb’s corporate blog, Gebbia said he will remain on the board of directors but transition to an advisory role, supporting co-founders Brian Chesky and Nathan Blecharczyk on “the roadmap, future concepts, and [Airbnb’s] creative culture.”",
    blogDay: blogDay,
    blogMonth: blogMonth,
    blogTime: blogTime,
    smallImg: smallImg3,
    bigImg: bigImg3,
    category: "Social Media",
    poster: "Kyle Wiggers",
    desc1:
      "“The primary reason for this transition is that this is the only company I’ve ever helped build, and my brain is bursting with more ideas to bring to the world,” Gebbia wrote. “My first new venture is a startup called parenthood, at which I’ll be taking on the role of Dad. The others involve a complementary product to Airbnb, documentary filmmaking, and various philanthropic initiatives. I’m looking forward to sharing more about these with you soon.”",
    desc2:
      "Gebbia co-launched Airbnb alongside Chesky, his roommate and former schoolmate at the Rhode Island School of Design, in San Francisco back in 2008. Originally called “AirBed & Breakfast,” the startup was invited by Paul Graham to join the 2009 Y Combinator winter cohort, which provided funding that the co-founders used to promote the fledgling service. In March 2009, the company shortened its name to “Airbnb” and expanded from air beds and shared spaces to properties including entire homes, apartments, and private rooms.",
    desc3:
      "Gebbia saw the company through its first year of profitability — 2016 — and IPO and the launch of services including Airbnb Experiences and Airbnb Plus, a collection of homes vetted for quality of services. He co-led Airbnb through its down moments, too, like battles with policymakers over the legality of Airbnb listings and a steep decline in business at the start of the COVID-19 pandemic.",
    desc4:
      "Gebbia was a major contributor to Airbnb’s early design, for example brainstorming the idea of offering free professional photography services to Airbnb hosts from a community of freelancers. He also spearheaded the founding of Airbnb.org in 2020, which enables hosts on Airbnb to house people in times of crises — personally donating $5 million helped kick off the fund’s efforts. ",
    bannerDesc:
      "Airbnb Experiences and Airbnb Plus, a collection of homes vetted for quality of services. He co-led Airbnb through its down moments, too, like battles with policymakers over the legality of Airbnb listings and a steep decline in business at the start of the COVID-19 pandemic.",
    heading1: "A cleansing hot shower or bath",
    heading2: "Lorem ipsum dolor sit amet, consectetur adipisicing",
    desc5:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus.",
    authorImg: authorImg3,
    authorDesc:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.",
    authorFB: "https://www.facebook.com",
    authorInsta: "https://www.instagram.com",
    authorTwitter: "https:www.twitter.com",
    authorLinkedIn: "https:www.linkedin.com",
  },
];

export default blogData;
