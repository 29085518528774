import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  About,
  Team,
  Service,
  Contact,
  BlogDetail,
  Footer,
  ServiceDetail,
} from "./Pages/IndexPages";
import Navbar from "./Components/Navbar";
import { PrivacyPolicy } from "./Components/PrivacyPolicy";
import blogData from "./Data/BlogData";
import { useState } from "react";
import { TermsOfUse } from "./Components/TermsOfUse";

function App() {
  const [data] = useState(blogData);

  console.log(data[0].data);
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service/:title" element={<ServiceDetail />} />
        <Route path="/blog/:poster" element={<BlogDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/terms_of_use" element={<TermsOfUse />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
