import React from "react";
import { useParams } from "react-router-dom";
import Hero from "../Components/Hero";
import blogData from "../Data/BlogData";
import "../Styles/BlogDetail.css";

const rn1000 = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;

const rn50 = Math.floor(Math.random() * (50 - 1 + 1) + 1);
const blogDate = new Date().toLocaleString();

function BlogDetail() {
  const { poster } = useParams();

  const product = blogData.find((product) => product.poster === poster);

  const {
    id,
    title,
    category,
    desc1,
    desc2,
    desc3,
    desc4,
    bigImg,
    bannerDesc,
    heading1,
    heading2,
    desc5,
    authorImg,
    authorDesc,
    authorFB,
    authorInsta,
    authorLinkedin,
    authorTwitter,
    smallImg,
  } = product;
  return (
    <>
      <div className="contact_container">
        <Hero pageName="Blog Detail" link="Home" />
      </div>
      <div className="product_detail_container">
        <div className="scontainer">
          <div className="row">
            <div className="col-lg-8 mb-5">
              <div className="blog_detail_content">
                <button className="btn blog_detail_button">{category}</button>
                <h1 className="blog_detail_title">{title}</h1>
                <div className="blog_detail_subinfo">
                  <p className="blog_detail_views">
                    <i class="fa-solid fa-eye "></i>&nbsp;
                    <span>{rn1000} Views</span>
                  </p>
                  <p className="blog_detail_comment">
                    <i class="fa-solid fa-comments"></i>&nbsp;
                    <span>{rn50} Comments</span>
                  </p>
                  <p className="blog_detail_comment">
                    <i class="fa-solid fa-calendar"></i>&nbsp;
                    <span>{blogDate}</span>
                  </p>
                </div>
                <div className="blog_detail_desc">
                  <p>{desc1}</p>
                  <br></br>
                  <p>{desc2}</p>
                  <br></br>
                  <h3 style={{ fontWeight: "bold" }}>{heading1}</h3>
                  <p>{desc3}</p>
                  <br></br>
                  <img src={bigImg} className="blog_detail_img" alt={title} />
                  <p>{desc4}</p>
                  <div className="banner_desc">
                    <p>{bannerDesc}</p>
                  </div>
                  <br></br>
                  <h3 style={{ fontWeight: "bold" }}>{heading2}</h3>
                  <p>{desc5}</p>
                </div>
              </div>
            </div>

            {/* Blog Detail Side Detail */}
            <div className="col-lg-4">
              {/* Blog Detail About Author */}
              <div className="blog_detail_about mb-5">
                <div className="blog_detail_about_text">
                  <div className="about">
                    <i class="fa-solid fa-grip-lines"></i>
                    <h4 className="blog_detail_about_title">About Author</h4>
                  </div>
                  <div className="about_img">
                    <img src={authorImg} alt="" />
                  </div>
                  <h4 className="blog_detail_about_title">{poster}</h4>
                  <p className="author_desc">{authorDesc}</p>
                  <div className="author_social_icons">
                    <a href={authorFB}>
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a href={authorInsta}>
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                    <a href={authorTwitter}>
                      <i class="fa-brands fa-twitter"></i>
                    </a>
                    <a href={authorLinkedin}>
                      <i class="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>

              {/*  Search Objects */}
              <div className="blog_detail_search blog_detail_about">
                <div className="blog_detail_about_text">
                  <div className="about">
                    <i class="fa-solid fa-grip-lines"></i>
                    <h4 className="blog_detail_about_title">Search Objects</h4>
                  </div>
                  <form className="newsletter_form footer_form">
                    <input type="text" placeholder="Enter email address" />
                    <button className="btn btn_newsletter blog_detail_button">
                      <a href="mailto:shavez9119@gmail.com">
                        <i class="fa-solid fa-paper-plane" title="Search"></i>
                      </a>
                    </button>
                  </form>
                </div>
              </div>

              {/* Popular Feeds */}

              <div className="blog_detail_popular_feeds blog_detail_about mt-5 mb-5">
                <div className="blog_detail_about_text">
                  <div className="about">
                    <i class="fa-solid fa-grip-lines"></i>
                    <h4 className="blog_detail_about_title">In Shorts</h4>
                  </div>
                  <div className="feed_container">
                    <img src={smallImg} className="feed_img" alt="" />
                    <div className="feed_text">
                      <h6>{title}</h6>
                      <p>
                        <i class="fa-solid fa-calendar-days"></i>&nbsp;
                        {blogDate}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogDetail;
