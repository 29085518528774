import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function TermsOfUse() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <div
        style={{ justifyContent: "center", alignItems: "center", margin: 40 }}
      >
        <h1
          style={{
            fontSize: 40,
            color: "#F2BB32",
            fontWeight: "bold",
            marginTop: 40,
            marginBottom: 40,
          }}
        >
          Terms of Service
        </h1>
        <h2 style={{ marginBottom: 20 }}>1. Acceptance of Terms</h2>
        <p style={{ marginBottom: 40 }}>
          By accessing or using our website, you agree to be bound by these
          Terms of Service. If you do not agree to these terms, please do not
          use our services.
        </p>
        <h2 style={{ marginBottom: 20 }}>2. Intellectual Property</h2>
        <p style={{ marginBottom: 40 }}>
          All content on our website, including text, graphics, logos, images,
          and software, is the property of our company and is protected by
          copyright and other intellectual property laws. You may not reproduce,
          distribute, modify, or create derivative works without our prior
          written consent.
        </p>
        <h2 style={{ marginBottom: 20 }}>3. Disclaimer of Liability</h2>
        <p style={{ marginBottom: 40 }}>
          We strive to provide accurate and up-to-date information on our
          website, but we make no warranties or representations about the
          accuracy, reliability, completeness, or timeliness of the content.
          Your use of the website and its content is at your own risk.
        </p>
        <h2 style={{ marginBottom: 20 }}>4. Limitation of Liability</h2>
        <p style={{ marginBottom: 40 }}>
          To the extent permitted by law, we shall not be liable for any direct,
          indirect, incidental, consequential, or punitive damages arising out
          of your use or inability to use our website or services, even if we
          have been advised of the possibility of such damages.
        </p>
        <h2 style={{ marginBottom: 20 }}>5. Changes to the Terms</h2>
        <p style={{ marginBottom: 40 }}>
          We reserve the right to modify or update these Terms of Service at any
          time without prior notice. Continued use of our website after any
          changes.
        </p>
      </div>
    </>
  );
}

export { TermsOfUse };
