import React, { useState, useEffect } from "react";
import logo from "../Assets/logo.png";
import "../Styles/Header.css";
import { Modal } from "./Modal";

function Header({ showHideMenu }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
      document.body.style.overflowX = "hidden";
    }
  });
  return (
    <>
      <div className="header">
        <div className="header_container">
          <div data-aos="fade-right" className="logo_container">
            <img src={logo} alt="Logo" />
          </div>
          <div className="element_container">
            <div data-aos="fade-down" className="single_element">
              <a href="tel:9091089804">
                <h4 className="element_text">
                  <i class="fa-solid fa-phone"></i> (+91) 9091089804
                </h4>
                <span>Call Us Now</span>
              </a>
            </div>
          </div>
          <div className="element_container">
            <div data-aos="fade-left" className="single_element">
              <a href="mailto:Pradhanmantrimudrayojna.gov@gmail.com">
                <h4 className="element_text">
                  <i className="fa-solid fa-envelope"></i>{" "}
                  Pradhanmantrimudrayojna.gov@gmail.com
                </h4>
                <span>Mail Us Now</span>
              </a>
            </div>
          </div>
          <div className="menu_icon">
            <i
              className="fa-solid fa-bars-staggered hamburger"
              onClick={showHideMenu}
            ></i>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      )}
    </>
  );
}

export default Header;
