import React from "react";
import { Link } from "react-router-dom";
import "../Styles/Hero.css";

function Hero(props) {
  return (
    <>
      <div
        data-aos="fade-down"
        // data-aos-duration="1500"
        data-aos-mirror="true"
        data-aos-once="false"
        data-aos-easing="ease-in-out"
        className="contact_hero_text"
      >
        <p>
          <Link to="/">{props.link}</Link>&nbsp; | &nbsp; {props.pageName}
        </p>
        <h1>{props.pageName}</h1>
      </div>
    </>
  );
}

export default Hero;
