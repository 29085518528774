import React, { useState } from "react";
import "../Styles/ServiceC.css";
import serviceCardData from "../Data/ServiceCardData";
import { Link } from "react-router-dom";
function Service() {
  const [serviceData] = useState(serviceCardData);
  return (
    <div className="service_container margin_top_1001">
      <div className="scontainer">
        <div className="row service_content">
          <div data-aos="fade-down"
          // data-aos-duration="1500"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-easing="ease-in-out" className="col-md-12">
            <p className="service_sub_title">Loan We Provide</p>
            <h1 className="service_title">What We Provide</h1><br/>
          </div>
        </div>
        <div className="row">
          {serviceCardData.map((serviceCardDetail) => {
            const { id, title, buttonText, img } =
              serviceCardDetail;
            return (
              <div className="col-xl-3 col-md-6 col-sm-12" key={id}>
                <div data-aos="fade-right"
          // data-aos-duration="1500"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-easing="ease-in-out" className="card">
                  <div className="circle">
                    <img src={img} alt="" />
                  </div>
                  <h2 className="service_card_title">{title}</h2>
                  <Link to="about">
                  {/* <a> */}
                    <button className="btn service_btn">
                      {buttonText} &#8594;
                    </button>
                  </Link>
                  {/* </a> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Service;
