import React from "react";
import { useParams } from "react-router-dom";
import serviceData from "../Data/ServiceData";
import Hero from "../Components/Hero";

function ServiceDetail() {
  const { title } = useParams();

  const services = serviceData.find((service) => service.title === title);

  const {
    id,
    bigImg,
    heading1,
    heading2,
    heading3,
    desc1,
    desc2,
    desc3,
    bannerDesc,
    faqTitle1,
    faqTitle2,
    faqTitle3,
    faqDesc1,
    faqDesc2,
    faqDesc3,
  } = services;
  return (
    <>
      <div className="contact_container">
        <Hero link="Home" pageName="Service Detail" />
      </div>
      <div className="service_detail_container">
        <div className="scontainer">
          <div className="row">
            <div className="col-lg-9">
              <div className="service_detail_right_content">
                <img src={bigImg} alt={title} />
                <div className="heading1">
                  <div className="space">
                    <h2>{heading1}</h2>
                    <p>{desc1}</p>
                  </div>
                  <div className="space">
                    <h2>{heading2}</h2>
                    <p>{desc2}</p>
                  </div>
                  <div className="space">
                    <h2>{heading3}</h2>
                    <p>{desc3}</p>
                  </div>
                  <div className="service_detail_banner">
                    <p>{bannerDesc}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <h3 className="service_detail_left_heading">Service Category</h3>
              <div className="service_detail_left_box">
                <p>General Contracting</p>
              </div>
              <div className="service_detail_left_box">
                <p>Appartment Design</p>
              </div>
              <div className="service_detail_left_box">
                <p>Building Construction</p>
              </div>
              <div className="service_detail_left_box">
                <p>Building Renovation</p>
              </div>
              <div className="service_detail_left_box">
                <p>General Contracting</p>
              </div>
              <div className="service_detail_left_box">
                <p>Appartment Design</p>
              </div>
              <h3 className="service_detail_left_heading">
                Download Brochures
              </h3>
              <div className="service_detail_left_box download">
                <p>
                  <i class="fa-solid fa-file-pdf"></i> Download .PDF
                </p>
              </div>
              <div className="service_detail_left_box download blue">
                <p>
                  <i class="fa-solid fa-file-word"></i> Download .DOC
                </p>
              </div>
              <div className="service_detail_left_box download">
                <p>
                  <i class="fa-solid fa-file-powerpoint"></i> Download .PPT
                </p>
              </div>
              <h3 className="service_detail_left_heading">Contact Us</h3>
              <div className="left_box">
                <div className="service_detail_left_para_box">
                  <p className="service_detail_left_icon">
                    <i class="fa-solid fa-phone-flip"></i>
                  </p>
                  <div className="service_detail_left_para">
                    <p>Phone Number</p>
                    <p className="service_detail_left_phone">9874566210</p>
                  </div>
                </div>
                <div className="service_detail_left_para_box">
                  <p className="service_detail_left_icon">
                    <i class="fa-solid fa-envelope"></i>
                  </p>
                  <div className="service_detail_left_para">
                    <p>Email Address</p>
                    <p className="service_detail_left_phone">
                      example@gmail.com
                    </p>
                  </div>
                </div>
                <div className="service_detail_left_para_box">
                  <p className="service_detail_left_icon">
                    <i class="fa-solid fa-location-dot"></i>
                  </p>
                  <div className="service_detail_left_para">
                    <p>Office Address</p>
                    <p className="service_detail_left_phone">
                      14/A, Ping Tower, NYC
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceDetail;
