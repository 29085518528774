import smallImg1 from "../Assets/Services/serviceSmall1.jpg";
import smallImg2 from "../Assets/Services/serviceSmall2.webp";
import smallImg3 from "../Assets/Services/serviceSmall3.webp";
import bigImg1 from "../Assets/Services/service1.jpg";
import bigImg2 from "../Assets/Services/service2.webp";
import bigImg3 from "../Assets/Services/service3.webp";

const serviceData = [
  {
    id: 1,
    bigImg: bigImg1,
    smallImg: smallImg1,
    title: "GM joint venture",
    heading1: "Start building construction planning",
    heading2: "Quality Construction",
    heading3: "What You Receive?",
    desc1:
      "The U.S. Department of Energy is reviving an old loan program and its first recipient is the joint battery venture between General Motors and LG Energy Solution. The $2.5 billion loan issued to GM and LG Energy will be used to help finance the construction of new lithium-ion battery cell manufacturing facilities.",
    desc2:
      "The loan to Ultium Cells, the name of the GM-LG joint venture, is expected to close in the coming months and will be used for its upcoming facilities in Ohio, Tennessee and Michigan. GM and LG have plans to invest more than $7 billion to jointly build three battery plants. Production at the Ohio factory is expected to begin in August. In Tennessee, production is scheduled for late 2023 and in Michigan for 2024.",
    desc3:
      "Tesla was one of the last automakers to receive a loan back in 2009, when the DOE loaned the EV-maker $465 million to help build its Model S sedan. To date, the program has dolled out $8 billion for products that have supported the production of more than 4 million advanced technology vehicles, according to the DOE. Ford and Nissan also received $5.9 billion in 2009 and $1.4 billion in 2010, respectively.",
    bannerDesc:
      "While the plan is the first time the DOE will issue a loan exclusively for a battery cell manufacturing project under the vehicle program, it’s not the first time we’re seeing governments get involved in securing battery supply.",
    faqTitle1: "100% Satisfaction Guarantee.",
    faqDesc1:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rhoncus dolor at libero ultricies ullamcorper vel ut dui. Maecenas sollicitudin risus non faucibus blandit. Nulla facilisi.",
    faqTitle2: "Accurate Testing Processes.",
    faqDesc2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rhoncus dolor at libero ultricies ullamcorper vel ut dui. Maecenas sollicitudin risus non faucibus blandit. Nulla facilisi.",
    faqTitle3: "35+ Years Of Experience.",
    faqDesc3:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rhoncus dolor at libero ultricies ullamcorper vel ut dui. Maecenas sollicitudin risus non faucibus blandit. Nulla facilisi.",
  },
  {
    id: 2,
    bigImg: bigImg2,
    smallImg: smallImg2,
    title: "Faraday’s future",
    heading1: "Start building construction planning",
    heading2: "Quality Construction",
    heading3: "What You Receive?",
    desc1:
      "Any challenges in supplier engagements, delays in ramping capacity or labor at the company’s Hanford, California manufacturing facility or for sales and service engagements, rising prices of materials, or ongoing global supply chain disruptions may further increase the need for additional capital to launch the FF 91 series, the company said in the filing.",
    desc2:
      "Faraday Future has had a tumultuous ride since its founding in 2014. The company came out of stealth in a glitzy event in January 2016 that unveiled the FFZero1, a futuristic, single-seat electric vehicle concept car. But it has never been able to turn the splash and hype into a real product.",
    desc3:
      "The company has had a long string of controversies, which have only seemed to compound after becoming a publicly traded company in July 2021 through a merger with Property Solutions Acquisition Corp. Just months after its public debut, a short seller report by J Capital alleged that Faraday Future had made a number of inaccurate statements.",
    bannerDesc:
      "Two months after Faraday Future’s internal investigation determined employees made inaccurate statements to investors and that its “corporate culture failed to sufficiently prioritize compliance,” the U.S. Securities and Exchange Commission subpoenaed several executives.",
    faqTitle1: "100% Satisfaction Guarantee.",
    faqDesc1:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rhoncus dolor at libero ultricies ullamcorper vel ut dui. Maecenas sollicitudin risus non faucibus blandit. Nulla facilisi.",
    faqTitle2: "Accurate Testing Processes.",
    faqDesc2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rhoncus dolor at libero ultricies ullamcorper vel ut dui. Maecenas sollicitudin risus non faucibus blandit. Nulla facilisi.",
    faqTitle3: "35+ Years Of Experience.",
    faqDesc3:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rhoncus dolor at libero ultricies ullamcorper vel ut dui. Maecenas sollicitudin risus non faucibus blandit. Nulla facilisi.",
  },
  {
    id: 3,
    bigImg: bigImg3,
    smallImg: smallImg3,
    title: "China’s Jaka Robotics fueled",
    heading1: "Start building construction planning",
    heading2: "Quality Construction",
    heading3: "What You Receive?",
    desc1:
      "Jaka Robotics, a Chinese startup that makes collaborative robots, has just pulled in a hefty Series D funding round of over $150 million from a lineup of heavyweight investors to help it expand globally.",
    desc2:
      "The round is led by Singapore’s sovereign wealth fund Temasek, TrueLight Capital, Softbank Vision Fund II, and Prosperity7 Ventures, a growth fund under Aramco Ventures, which is an investment subsidiary of Saudi Arabia’s state oil firm Aramco.",
    desc3:
      "Collaborative robots, known as “cobots”, are meant to work alongside humans rather than in isolation. Based out of Shanghai and Beijing, Jaka’s robotic arms can augment humans in a range of tasks, from assembling electronic parts, pouring from a coffee machine to packaging smartphones.",
    bannerDesc:
      "Jaka already counts Toyota and Schneider as its close partners, who are co-developing large-scale applications with the startup using its robotic solutions.",
    faqTitle1: "100% Satisfaction Guarantee.",
    faqDesc1:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rhoncus dolor at libero ultricies ullamcorper vel ut dui. Maecenas sollicitudin risus non faucibus blandit. Nulla facilisi.",
    faqTitle2: "Accurate Testing Processes.",
    faqDesc2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rhoncus dolor at libero ultricies ullamcorper vel ut dui. Maecenas sollicitudin risus non faucibus blandit. Nulla facilisi.",
    faqTitle3: "35+ Years Of Experience.",
    faqDesc3:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rhoncus dolor at libero ultricies ullamcorper vel ut dui. Maecenas sollicitudin risus non faucibus blandit. Nulla facilisi.",
  },
];

export default serviceData;
