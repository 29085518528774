import React, { useState, useEffect } from "react";
import "../Styles/ServiceP.css";
import approachData from "../Data/ApproachData";
import Hero from "../Components/Hero";
import { Link } from "react-router-dom";
import Process from "../Components/Process";
import { useLocation } from 'react-router-dom';
function Service() {

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [data, setData] = useState(approachData);
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      <div className="contact_container">
        <Hero link="Home" pageName="Services" />
      </div>
      <div   data-aos="fade-down"
                  // data-aos-duration="1500"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  data-aos-easing="ease-in-out" className="servicep_container">
        <div className="scontainer">
          <div className="row">
            {data.map((detail) => {
              const { id, img, title, desc } = detail;
              return (
                <div className="col-lg-4 col-md-6 col-xs-12" key={id}>
                  <div className="servicep_card">
                    <img src={img} alt={title} />
                    <div className="servicep_text">
                      <h4>{title}</h4>
                      <p>
                        {desc}
                      </p>
                      <Link
                        to='/about'
                        className="service_readmore"
                      >
                        Learn More <i class="fa-solid fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Process />
        </div>
      </div>
    </>
  );
}

export default Service;
