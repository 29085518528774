import React, { useState } from "react";
import faqImg from "../Assets/faq.png";
import faqData from "../Data/FAQData";
import FAQ from "./FAQ";

function FAQS() {
  const [data] = useState(faqData);
  return (
    <>
      <div className="faq_container">
        <div className="scontainer">
          <div className="row margin_top_1001 align_center">
            <div data-aos="fade-right"
          // data-aos-duration="1500"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-easing="ease-in-out" className="col-md-6">
              <div className="faq_text">
                <p className="exp_super_title">Get Answers</p>
                <h1 className="exp_title">
                  Get every single answers from here
                </h1>
              </div>
              <img src={faqImg} alt="FAQ Img" />
            </div>
            <div data-aos="fade-left"
          // data-aos-duration="1500"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-easing="ease-in-out" className="col-md-6">
              <div className="faq_right_content">
                {data.map((detail) => {
                  return <FAQ {...detail} key={detail.id} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQS;
