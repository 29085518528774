import React, { useState, useRef } from "react";
import "../Styles/Modal.css";
import emailjs from '@emailjs/browser';

const Modal = ({ isModalOpen, setIsModalOpen }) => {
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    address: "",
    amount: "",
    message: "",
  };

  const [formValue, setFormValue] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleContactSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validation(formValue));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const validation = (values) => {
    const errors = {};
    const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!values.name) {
      alert("Please Enter Name *")
      errors.name = "Please Enter Name *"
    }else if (!values.phone) {
      alert("Please Enter Phone *")
      errors.phone = "Please Enter Phone *"
    } else if (values.phone.length > 10) {
      alert("Phone can't be more than 10 digit *")
      errors.phone = "Phone can't be more than 10 digit *"
    } else if (values.phone.length < 10) {
      alert("Phone can't be less than 10 digit *")
      errors.phone = "Phone can't be less than 10 digit *"
    }
    //  else if (!values.address) {
    //   alert("Please Enter Address *")
    //   errors.address = "Please Enter Address *"
    // } 
    else {
      alert(
        `Form Submitted Successfully!
We will catch you soon`
      );
      setIsModalOpen(!isModalOpen)
      values.name = "";
      values.email = "";
      values.phone = "";
      values.subject = "";
      values.address = "";
      values.amount = "";
      values.message = "";
    }
    return errors;
  };

  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setFormErrors(validation(formValue));
    emailjs.sendForm('service_vus6jsc', 'template_3v387ll', form.current, 'noe_ruuOARN3ipKY8')
      .then((result) => {
          console.log(result.text);
          console.log("form sent!")
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          zIndex: 1000,
          top: 0,
          backgroundColor: "rgba(0,0,0,0.6)",
          height: "100%",
          width: "100%",
        }}
        
      >
        <div className="modal_container">
          <div className="contact_form_container">
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
              <h3
                style={{ textAlign: "left", paddingTop: 20, paddingLeft: 35,fontWeight:"bold" }}
              >
                Apply For Loan
              </h3>
              <h3 style={{cursor:'pointer',marginTop:22,color:"red",fontWeight:"bold", marginRight:20}} onClick={() => setIsModalOpen(!isModalOpen)}>X</h3>
            </div>
            <div className="scontainer">
              <form
              ref={form}
                className="contact_form"
                action="https://formspree.io/f/mvonwbqo"
                method="post"
                onSubmit={sendEmail}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="error_label">
                      <label htmlFor="full name">
                        Full Name <span className="required">*</span>
                      </label>
                    </div>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter Your Name"
                      value={formValue.name}
                      onChange={handleInputChange}
                    />
                    <p className="val_msg name">{formErrors.name}</p>
                  </div>
                 
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="error_label">
                      <label htmlFor="phone">
                        Phone Number <span className="required">*</span>
                      </label>
                    </div>
                    <input
                      type="number"
                      name="phone"
                      id="phone"
                      maxLength={10}
                      placeholder="Enter Your Mobile"
                      value={formValue.phone}
                      onChange={handleInputChange}
                    />
                    <p className="val_msg name">{formErrors.phone}</p>
                  </div>

                  <div className="col-md-12">
                    <div className="error_label">
                      <label htmlFor="email">
                        Email Address 
                      </label>
                    </div>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter Your Email"
                      value={formValue.email}
                      onChange={handleInputChange}
                    />
                    <p className="val_msg name">{formErrors.email}</p>
                  </div>
                  <div className="col-md-12">
                    <div className="error_label">
                      <label htmlFor="address">
                        Full Address 
                      </label>
                    </div>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Enter Your Address"
                      value={formValue.address}
                      onChange={handleInputChange}
                    />
                    <p className="val_msg name">{formErrors.address}</p>
                  </div>
                  <div className="col-md-12">
                    <div className="error_label">
                      <label htmlFor="subject">
                        Loan Amount Required <span className="required"></span>
                      </label>
                    </div>
                    <input
                      type="text"
                      name="amount"
                      id="amount"
                      placeholder="Enter Loan Amount"
                      value={formValue.amount}
                      onChange={handleInputChange}
                    />
                    <p className="val_msg name">{formErrors.amount}</p>
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <label htmlFor="subject" style={{ float: "left" }}>
                    Enter Message
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="7"
                    placeholder="Enter Message..."
                    value={formValue.message}
                    onChange={handleInputChange}
                  ></textarea>
                </div> */}
                <input type="submit" className="btn contact_btn" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { Modal };
